import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async get(lawyerId) {
    let result = await axios.get(`${API_ROUTES.processlawyer.get}/${lawyerId}`);

    return result;
  },

  async save(data) {
    let result = await axios.post(`${API_ROUTES.processlawyer.save}`, data);

    return result;
  },

  async delete(id) {
    let result = await axios.delete(`${API_ROUTES.processlawyer.delete}/${id}`);

    return result;
  },

  async update(id, data) {
    let result = await axios.patch(
      `${API_ROUTES.processlawyer.update}/${id}`,
      data
    );

    return result;
  },

  async getDocuments(processLawyertId) {
    let result = await axios.get(
      `${API_ROUTES.processlawyersDocuments.get}/${processLawyertId}`
    );

    return result;
  },

  async uploadDocument(data) {
    let result = await axios.post(
      `${API_ROUTES.processlawyersDocuments.save}`,
      data
    );

    return result;
  },

  async deleteDocument(id) {
    let result = await axios.delete(
      `${API_ROUTES.processlawyersDocuments.delete}/${id}`
    );

    return result;
  },

  async find(processLawyertId) {
    let result = await axios.get(
      `${API_ROUTES.processlawyerOne.get}/${processLawyertId}`
    );

    return result;
  }
};
